import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'

const NewsListTemplate = ({ data, pageContext }) => {
  const {
    site: {
      siteMetadata: { title },
    },
    allMarkdownRemark: { edges: news },
  } = data

  return (
    <>
      <Helmet>
        <html className="news" lang="ja" />
        <title>{`トピックス | ${title}`}</title>
      </Helmet>
      <Layout>
        <div className="common-header clearfix">
          <h1>トピックス</h1>
          <div className="breadcrumb">
            <li>
              <Link to={`/`}>トップページ</Link>
            </li>
            /<li>トピックス</li>
          </div>
        </div>
        <div className="main-wrapper">
          <div className="container">
            {news.map((aNews) => (
              <div key={aNews.node.id} className="topic">
                <div className="wrapper">
                  <p className="date">{aNews.node.frontmatter.date}</p>
                  <p className="title">
                    <Link to={aNews.node.fields.slug}>
                      {aNews.node.frontmatter.title}
                    </Link>
                  </p>
                </div>
              </div>
            ))}

            <Pagination {...pageContext} />
          </div>
        </div>
      </Layout>
    </>
  )
}

NewsListTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default NewsListTemplate

export const query = graphql`
  query NewsPaginatableListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news-template" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  }
`
