import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ numPages, currentPage, totalCount, limit }) => {
  const previousPageIndex = currentPage - 1
  const nextPageIndex = currentPage + 1
  const pageNumbersFrom = 1 + (currentPage - 1) * limit
  const pageNumbersTo =
    10 * currentPage > totalCount ? totalCount : 10 * currentPage
  const isLastPage = currentPage === numPages
  const isFirstPage = (page) => page === 1
  const isCurrentPage = (page) => page === currentPage

  return (
    <div className="pagenation-area">
      <div className="wrapper">
        <div className="pagenation">
          <div className={`prev ${isFirstPage(currentPage) && 'non-active'}`}>
            <Link
              to={
                isFirstPage(previousPageIndex)
                  ? `/news`
                  : `/news/${previousPageIndex}`
              }
            >
              前へ
            </Link>
          </div>
          <div className="numbers">
            {Array.from({ length: numPages }).map((_, i) => {
              const pageIndex = i + 1

              return (
                <div
                  key={pageIndex}
                  className={`number ${isCurrentPage(pageIndex) && 'current'}`}
                >
                  <Link
                    to={isFirstPage(pageIndex) ? `/news` : `/news/${pageIndex}`}
                  >
                    {pageIndex}
                  </Link>
                </div>
              )
            })}
          </div>
          <div className={`next ${isLastPage && 'non-active'}`}>
            <Link to={`/news/${nextPageIndex}`}>次へ</Link>
          </div>
        </div>
        <div className="count">{`全${totalCount}件中、${pageNumbersFrom} - ${pageNumbersTo}件を表示中`}</div>
      </div>
    </div>
  )
}

export default Pagination
